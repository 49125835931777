import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"

import logo from "@images/logos/xywav-logo.svg"

const NotFoundPage = () => {
  const location = { pathname: "/404" }

  return (
    <Layout
      location={location}
      headerType="agnostic"
      footerType="agnostic"
      copyright={
        <>
          &copy; 2024 Jazz Pharmaceuticals, Inc.{" "}
          <nobr>US-XYW-2200416 Rev1124</nobr>
        </>
      }
    >
      <Seo
        title="Page not found | XYWAV®"
        meta={[{ name: "robots", content: "noindex" }]}
        location={location}
      />
      <section
        tw="relative pt-12 pb-14 overflow-hidden xl:(pt-20 pb-16)"
        className="error-404"
      >
        <Container>
          <img
            src={logo}
            tw="hidden lg:(absolute block w-[24.813rem] top-[2.3rem] right-10)"
            alt=""
          />
          <h1
            css={[
              tw`font-cocon font-bold text-[32px] leading-[1] mb-6 xl:(text-6xl mb-8)`,
            ]}
          >
            Page not found
          </h1>
          <div tw="text-xl mb-8">
            Sorry, the page you requested was not found.
          </div>
          <div tw="text-center lg:text-left">
            <PrimaryCta url="/" width={tw`lg:w-auto`}>
              Back to home
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage
